import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { Switch } from '@entur/form';
import { Label } from '@entur/typography';
import { colors } from '@entur/tokens';
import { BusIcon, FerryIcon } from '@entur/icons';
import { Playground } from '~/components/playground/Playground';
import { ImportStatement } from '~/components/ImportStatement';
import PageHeader from '~/components/PageHeader';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import doPic from './SwitchDo.png';
import dontPic from './SwitchDont.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'() => {\n  const [checked, setChecked] = React.useState(false)\n  return (\n    <div>\n      <Label>Ønsker du å motta nyhetsbrev?</Label>\n      <Switch checked={checked} onChange={() => setChecked(!checked)}>\n        Ja takk\n      </Switch>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Switch,
      Label,
      colors,
      BusIcon,
      FerryIcon,
      Playground,
      ImportStatement,
      PageHeader,
      DoDontGroup,
      DoDontCard,
      doPic,
      dontPic,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [checked, setChecked] = React.useState(false);
        return <div>
        <Label mdxType="Label">Ønsker du å motta nyhetsbrev?</Label>
        <Switch checked={checked} onChange={() => setChecked(!checked)} mdxType="Switch">
          Ja takk
        </Switch>
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "switch"
    }}>{`Switch`}</h3>
    <ImportStatement imports="Switch" mdxType="ImportStatement" />
    <Props of={Switch} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Switch er en kontroll som lar brukerne veksle mellom to mulige tilstander (av eller på).
Switch brukes kun for binære handlinger som aktiveres eller deaktiveres umiddelbart etter at brukeren har slått på eller av bryteren.
Det vil si at endring i tilstanden ikke skal kreve bekreftelse eller lagring. Switch bør ha en ledetekst som forteller hva brukeren slår av og på.`}</p>
    <p>{`For handlinger der brukeren må bekrefte valget sitt, bruk Checkboxes istedenfor.`}</p>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard variant="success" src={doPic} mdxType="DoDontCard">
    Bruk switch til å slå på eller av en funksjon/innstilling som gir automatisk
    oppdatering eller automatisk lagring.
  </DoDontCard>
  <DoDontCard variant="negative" src={dontPic} mdxType="DoDontCard">
    Unngå å bruk radioknapper for å slå innstillinger av eller på. Radioknapper
    indikerer at bare en kan velges om gangen og krever et ekstra steg for
    lagring.
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      